import { Container, Stack, Row, Col, Card } from 'react-bootstrap';
import { serviceTitle, serviceSubTitle, serviceData } from '../../staticData/service.data';

const Services = () => {
    return <>
        <Container>
            <Stack gap={1} className="text-center mt-5 mb-3">
                <h2>{serviceTitle}</h2>
                <i>{serviceSubTitle}</i>
            </Stack>

            <Row xs={1} md={3} className="p-5">
                {
                    serviceData.map((item: { title: string, description: string }, index: number) => {
                        return <Col key={index} className="p-2">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                    })
                }
            </Row>
        </Container>
    </>
}

export default Services;