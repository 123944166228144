import {
    Container,
    Row,
    Col,
    Image,
    Form,
    Button
} from 'react-bootstrap';
import { Phone, Envelope, GeoAlt } from 'react-bootstrap-icons';
import { contactUsData } from '../../staticData/contactUs.data';
import { Fragment, useEffect, useState } from 'react';
const ContactUs = () => {
    const [showGiveUsAMsg, setShowGiveUsAMsg] = useState(true);
    const [sendBtnDisable, setSendBtnDisable] = useState(true);
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [comments, setComments] = useState('');
    const [isFormValid, setIsFormValid] = useState([false, false, false, false, false]);

    const giveUsMsgHandler = (event: any, field: string) => {
        const value = event.target.value;
        let newArr = [...isFormValid];

        switch (field) {
            case 'name':
                setName(value);
                if (value.length === 0) {
                    newArr[0] = true;
                    setIsFormValid(newArr);
                } else {
                    newArr[0] = false;
                    setIsFormValid(newArr);
                }
                break;
            case 'companyName':
                setCompanyName(value);
                if (value.length === 0) {
                    newArr[1] = true;
                    setIsFormValid(newArr);
                } else {
                    newArr[2] = true;
                    setIsFormValid(newArr);
                }
                break;
            case 'emailId':
                setEmailId(value);
                break;
            case 'phoneNo':
                setPhoneNo(value);
                break;
            case 'comments':
                setComments(value);
                break;
        }
    }

    useEffect(() => {
        setSendBtnDisable(isFormValid.includes(false));
    }, [isFormValid])

    return <Container fluid className="p-0">
        <Image src={'../../images/contactus.jpg'} fluid className='mb-5' />
        <Container>
            <Row className='mb-5'>
                <Col className='mb-4' xs={12} sm={6} md={6} lg={6}>
                    <h3>Contact Us</h3>
                    <Row>
                        <Col xs={12} className='mb-2'><GeoAlt /> {contactUsData.name}</Col>
                        <Row className='mb-2' style={{ marginLeft: '7px' }}>
                            {
                                contactUsData.address.map((address, index) => {
                                    return <Fragment key={index}>
                                        <Col xs={12}>{address}</Col>
                                    </Fragment>
                                })
                            }
                        </Row>
                        <Col xs={12}><Envelope /> {contactUsData.emailId}</Col>
                        <Col xs={12}><Phone /> {contactUsData.contactNo}</Col>
                    </Row>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    {
                        showGiveUsAMsg &&
                        <>
                            <h5 className='mb-4'>Give us a Message</h5>
                            <Form>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Your Name</Form.Label>
                                            <Form.Control type="text"
                                                onChange={(e) => giveUsMsgHandler(e, 'name')}
                                                value={name}
                                                placeholder="Please enter your Name" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Your Company Name</Form.Label>
                                            <Form.Control type="text"
                                                onChange={(e) => giveUsMsgHandler(e, 'companyName')}
                                                value={companyName}
                                                placeholder="Please enter your Company Name" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Your Email ID</Form.Label>
                                            <Form.Control type="text"
                                                onChange={(e) => giveUsMsgHandler(e, 'emailId')}
                                                value={emailId}
                                                placeholder="Please enter your Email ID" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Your Phone Number</Form.Label>
                                            <Form.Control type="text"
                                                onChange={(e) => giveUsMsgHandler(e, 'phoneNo')}
                                                value={phoneNo}
                                                placeholder="Please enter your Phone Number" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Comments</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                onChange={(e) => giveUsMsgHandler(e, 'comments')}
                                                value={comments}
                                                placeholder="Please enter your Comments" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button style={{
                                    backgroundColor: '#03BFD5',
                                    border: 'none'
                                }} variant="primary"
                                    disabled={sendBtnDisable}
                                    onClick={() => setShowGiveUsAMsg(false)}>
                                    SEND
                                </Button>
                            </Form>
                        </>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <iframe
                        title='map'
                        src={contactUsData.map}
                        width="100%"
                        height="450"
                        loading="lazy"></iframe>
                </Col>
            </Row>
        </Container>
    </Container>
}

export default ContactUs;