import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./components/common/Footer/Footer";
import Header from "./components/common/Header/Header";
import Router from "./router/Router";
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './style/GlobalStyle';

const App = () => {
  return <>
    <GlobalStyle/>
    <BrowserRouter>
      <Header />
      <Router />
      <Footer />
    </BrowserRouter>
  </>
}

export default App;
