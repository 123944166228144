export const serviceTitle = `OUR SERVICES`;

export const serviceSubTitle = `Savi Software Solution offers an extensive range of solutions
which helps the clients to implement the best practices in the industry.`;

export const serviceData = [
    {
        title: `Java Web Application Developement`,
        description: `Developing a java web application is a broad process that starts from deployment of client-side applications to server side implementation. This complex process requires a systematic approach and process-oriented methodology towards its development.`
    },
    {
        title: `Website Design and Development`,
        description: `SAVI, we provide unique, professional designs and clever user interface to the viewer.SAVI is committed to provide high quality, cost-effective and timely solutions. Knoinf is dedicated to build long term partnerships with it's clients through a program of excellent post implementation support.`
    },
    {
        title: `Consulting Services`,
        description: `SAVI Consulting Services aims at outsourcing a consultants for our clients. Knoinf has maintained strong personnel in different domains such as Education, Healthcare.`
    }
];