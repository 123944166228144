import Carousel from 'react-bootstrap/Carousel';
import { Image } from 'react-bootstrap';
import bannerData from '../../staticData/banner.data';

const Banner = () => {
    return <Carousel>
        {
            bannerData.map((item, index) => {
                return <Carousel.Item key={index}>
                    <Image src={item.imagePath} fluid />
                </Carousel.Item>
            })
        }
    </Carousel>
}

export default Banner;