import styled from "styled-components";

export const JobTitle = styled.div`
    font-size:21px;
    color: #e3060a;
    background: #e8e8e8;
    padding: 7px 10px;
    margin-bottom: 10px;
`;

export const Experience = styled.div`
    margin-bottom:1rem;
`;

export const JobDescription = styled.div`
    margin-bottom:1rem;
`;

export const SkillsRequiredHeader = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:1rem;
`;

export const SkillsRequired = styled.div`
    margin-bottom:1rem;
`;

export const DropResume = styled.a<any>`
    margin-bottom:1rem;
    color: #e3060a;
    font-weight:bold;
    text-decoration: none;
    cursor:pointer;
    display: block;
`;