export const carrerData = [{
    jobTitle: `Urgent Opening for Java Web Application Developer`,
    experience: `Freshers to 3+ Year Experience in Java Development`,
    jobDescription: `We are seeking Software Engineer with strong technical expertise in Java and familiarity with Front technologies to join us as we disrupt the mobile market. As you will have a direct impact on a product that reaches millions of users you must be familiar with software design methods and techniques, and have the skills to write, edit and de-bug computer programs to achieve design objectives. Ideally, you must have good communication, project management and analytical skills and good at analysing both the business and technical requirements for a software program and develop design solutions to meet the needs`,
    skillsRequired: <>
        <p>Language : Core Java and very good understanding of collections and exception handling.</p>
        <p>Java Framework - Spring 6.0, Springboot 3.2, JPA 3.1.</p>
	<p>Very good understanding of New Relic and Splunk tools.</p>
	<p>Understanding of good RDBMS design. Experience in writing complex SQL queries and optimizing queries.</p>
	<p>Good knowledge in Unit testing and automation test tools like JUnit, etc.</p>
	<p>Very good understanding of New Relic and Splunk tools.</p>
        <p>Should be able to join immediately if selected.</p>
    </>,
}, {
    jobTitle: `Urgent Opening for PHP Developer`,
    experience: `Freshers to 4+ Year Experience in PHP Deelopment`,
    jobDescription: `Strong knowledge of PHP web frameworks.User authentication and authorization between multiple systems, servers, and environments.Familiarity with MySQL databases and their declarative query languages`,
    skillsRequired: <>
	<p>Very Sound Knowledge in CodeIgniter.</p>
        <p>Proficient understanding of code versioning tools, such as Git. PHP, Codeignter, CakePHP, NodeJS, React JS, Third Party API Integration, Rest API, MySql, jQuery.</p>
        <p>Front-end technologies such as JavaScript, Jquery, HTML5, and CSS3.</p>
	<p>Should have good understanding of production deployment.</p>
        <p>Should be able to join immediately if selected.</p>,
    </>,
}];