const aboutUsData = <>
    <p>
        SAVI Software Solutions was founded in 2023 with the core mission of providing High-Quality design, Human Resources Management System, Payroll Processing System, School and Campus Management, Inventory Management System, e-Commerce, ERP, Web portal design & Hosting, Asset Management, Custom Application Development, Technology Consulting and IT infrastructure services.
    </p>

    <p>
       We take on projects both big and small, work and really enjoy hammering out problems and getting results. we love taking on challenging projects that require full-on content strategy, thoughtful design, demanding development, and ongoing marketing.
    </p>

    <p>
        Headquartered and fully equipped development center in Salem, we are a strong team with professionals experts, who work with integrity and passion to deliver world class IT solutions.
    </p>
</>;

export default aboutUsData;
