import Home from "../components/Home/Home";
import Careers from '../components/Careers/Careers';
import AboutUs from '../components/AboutUs/AboutUs';
import ContactUs from '../components/ContactUs/ContactUs';
import Services from "../components/Services/Services";

export const menuItem = [{
    name: `Home`,
    hyperlink: `/`,
    component: <Home />
}, {
    name: `About us`,
    hyperlink: `/about-us`,
    component: <AboutUs />
}, {
    name: `Services`,
    hyperlink: '/services',
    component: <Services />
}, {
    name: `Careers`,
    hyperlink: `/careers`,
    component: <Careers />
}, {
    name: `Contact us`,
    hyperlink: `/contact-us`,
    component: <ContactUs />
}];