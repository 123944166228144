import { Routes, Route } from 'react-router-dom';
import { menuItem } from '../staticData/menu.data';

const Router = () => {
    return <>
        <Routes>
            <Route path="/" element={menuItem[0].component} />
            {
                menuItem.map((item: { name: string, hyperlink: string, component: any }, index: number) => {
                    return <Route key={index} path={item.hyperlink} element={item.component} />
                })
            }
        </Routes>
    </>
}

export default Router;