export const contactUsData = {
    name: `Savi Software Solution`,
    address: [
        `No: U5, RBT Mall`,
        `Underground Floor`,
        `Meyyanur Byepass Road`,
        `Salem - 636 004.`,
    ],
    emailId: `tohr@savisoftwaresolution.com`,
    contactNo: `+91 9500631173`,
    map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.3769834372765!2d78.13354007505521!3d11.66767298854056
    3!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf041ac1e70f1%3A0x55fb3c452bb7572c!2sRBT%20Mall!5e0!3m2!1sen!2
    sin!4v1713956751231!5m2!1sen!2sin`
};