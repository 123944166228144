import { Col, Container, Row } from "react-bootstrap";
import { menuItem } from "../../../staticData/menu.data";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return <Container fluid style={{
        background: '#000000',
        padding: '20px 0',
        color: '#ffffff',
        marginTop: '3rem'
    }}>
        <Container>
            <Row>
                <Col xs={12} sm={6}>
                    <Row style={{ padding: '0 1rem' }}>
                        {
                            menuItem.map((menu, index) => {
                                return <Fragment key={index}>
                                    <div style={{
                                        width: '80px',
                                        padding: '0'
                                    }}>
                                        <Link to={menu.hyperlink} style={{
                                            cursor: 'pointer',
                                            color: '#ffffff',
                                            textDecoration: 'none',
                                            fontSize: '14px'
                                        }}>{menu.name}
                                        </Link>
                                    </div>
                                </Fragment>
                            })
                        }
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <div style={{
                        fontSize: '12px',
                        float: 'right'
                    }}>© Copyright 2024 Savi Software Solution.</div>
                </Col>
            </Row>
        </Container>
    </Container>
}

export default Footer;