import { Navbar, Image, Nav,Button  } from "react-bootstrap";
import styled from "styled-components";

export const StyledNavBar = styled(Navbar)`
    box-shadow: 2px 2px 3px rgba(0,0,0,.1);
    padding:0 0.5em;

`;

export const StyledImage = styled(Image)`
    width:180px;
    height:91px;
    cursor: pointer;
`;

export const StyledNav = styled(Nav)<{isactive: number}>`
    padding: 0 1rem;
    margin-top:1.5rem;
    a {
        text-decoration: none;
        color: ${(props) => props.isactive ? '#007aff' : '#7f809b'};
        font-size:1rem;
        margin-bottom:0.5rem;
    };
`;

export const RightPanel = styled.div`
    font-size:13px;
    padding:0.5rem;
`;

export const GetQuotePanel = styled(Button)<any>`
    background-color:#4a5fa3;
    margin-top: 1rem;
`;