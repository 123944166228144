import Services from "../Services/Services";
import AboutUs from "../AboutUs/AboutUs";
import Banner from "../Banner/Banner";
import Careers from "../Careers/Careers";
import ContactUs from "../ContactUs/ContactUs";

const Home = () => {
    return <>
        <Banner />
        <Services />
        <AboutUs/>
        <Careers/>
        <ContactUs/>
    </>
}

export default Home;