import {
    Container,
} from 'react-bootstrap';
import { PageHeader, PageSubHeader, SubBanner } from '../../style/GlobalStyle';
import { DropResume, Experience, JobDescription, JobTitle, SkillsRequired, SkillsRequiredHeader } from './subcomponent';
import { carrerData } from '../../staticData/carrer.data';
import { Fragment } from 'react';
import { EnvelopeFill } from 'react-bootstrap-icons';
import { contactUsData } from '../../staticData/contactUs.data';

const Careers = () => {
    return <Container fluid className="p-0">
        <SubBanner>
            <Container>
                <PageHeader>Career</PageHeader>
                <PageSubHeader>Jobs at Savi Software Solution</PageSubHeader>
            </Container>
        </SubBanner>
        <Container>
            {
                carrerData.map((title, index) => {
                    return <Fragment key={index}>
                        <JobTitle>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#e3060a"
                                style={{
                                    marginRight: '1rem'
                                }}
                                className="bi bi-suitcase-lg-fill" viewBox="0 0 16 16">
                                <path d="M7 0a2 2 0 0 0-2 2H1.5A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 
                                14H2a.5.5 0 0 0 1 0h10a.5.5 0 0 0 1 0h.5a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 
                                2H11a2 2 0 0 0-2-2zM6 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zM3 13V3h1v10zm9 0V3h1v10z" />
                            </svg>
                            {title.jobTitle}
                        </JobTitle>
                        <Experience>{title.experience}</Experience>
                        <JobDescription>{title.jobDescription}</JobDescription>
                        <SkillsRequiredHeader>Skill Required:</SkillsRequiredHeader>
                        <SkillsRequired>{title.skillsRequired}</SkillsRequired>
                    </Fragment>
                })
            }
            <DropResume
                href={`mailto:${contactUsData.emailId}?subject=My Resume`}>
                drop your resume here  <EnvelopeFill />
            </DropResume>
        </Container>
    </Container>
}

export default Careers;