import {
    Container,
} from 'react-bootstrap';
import { PageHeader, PageSubHeader, SubBanner } from '../../style/GlobalStyle';
import aboutUsData from '../../staticData/aboutUs.data';

const AboutUs = () => {
    return <Container fluid className="p-0">
        <SubBanner>
            <Container>
                <PageHeader>About us</PageHeader>
                <PageSubHeader>Who we are?</PageSubHeader>
            </Container>
        </SubBanner>
        <Container>
            {aboutUsData}
        </Container>
    </Container>
}

export default AboutUs;