import styled, { createGlobalStyle } from "styled-components";


const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: saviFont;
        src: url('font/Ubuntu-Regular.ttf');
    }
  
    body {
        //font-family: saviFont;
        font-family: normal 14px "Roboto",sans-serif;
    }

    .list-group-item {
        border:none;
    }
`;

export const SubBanner = styled.div`
    background: #1e1e1e url(images/bg-banner.jpg) no-repeat center top;
    padding: 60px 0;
    background-size: cover;
    text-align: center;
    margin-bottom:2rem;
`;

export const PageHeader = styled.h2`
    color:#ffffff;
    letter-spacing:2px;
`;

export const PageSubHeader = styled.h6`
    color:#ffffff;
`;

export default GlobalStyle;