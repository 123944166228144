import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    StyledNavBar,
    StyledImage,
    StyledNav,
} from "./subcomponent";
import { menuItem } from "../../../staticData/menu.data";


const Header = () => {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const setExpandedHandler = (value: boolean) => {
        window.scrollTo(0, 0);
        setExpanded(value)
    }

    return <>
        <StyledNavBar expanded={expanded} expand="lg" id="nav" bg="white" variant="white" sticky="top">
            <StyledNavBar.Brand onClick={() => navigate('/')}>
                <StyledImage src="../../images/brand_name_new.png" fluid />
            </StyledNavBar.Brand>

            <StyledNavBar.Toggle onClick={() => setExpandedHandler(!expanded)} aria-controls="basic-navbar-nav" />
            <StyledNavBar.Collapse id="basic-navbar-nav">
                {
                    menuItem.map((item, index) => {
                        return <StyledNav key={index} className="mr-auto"
                            isactive={location.pathname === item.hyperlink ? 1 : 0}>
                            <Link onClick={() => setExpandedHandler(false)} to={item.hyperlink}>{item.name}</Link>
                        </StyledNav>
                    })
                }
            </StyledNavBar.Collapse>
        </StyledNavBar>
    </>
}

export default Header;